//获取改签单详情
import consumer_t_od_trains_order_change_getTrainsChangeOrderDetailV2
  from '@/lib/data-service/haolv-default/consumer_t_od_trains_order_change_getTrainsChangeOrderDetailV2'
import consumer_insurance_getEInsuranceOrderUrl
  from '@/lib/data-service/haolv-default/consumer_insurance_getEInsuranceOrderUrl'
//获取审批进度
import consumer_trains_order_getSchedule from '@/lib/data-service/haolv-default/consumer_trains_order_getSchedule'
import consumer_air_ticket_ApprovalMethod from '@/lib/data-service/haolv-default/consumer_air_ticket_ApprovalMethod'
import consumer_admin_trains_order_applyForRefundCost
  from '@/lib/data-service/haolv-default/consumer_admin_trains_order_applyForRefundCost'
import consumer_trains_order_applyForRefund from '@/lib/data-service/haolv-default/consumer_trains_order_applyForRefund'
import consumer_trains_order_cancelChangeOrder
  from '@/lib/data-service/haolv-default/consumer_trains_order_cancelChangeOrder'
import {awaitWrap} from '@/page/admin/company-management/staff-management/common/unit'
import TrainTimetable from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/train-timetable/1.0.0/index.vue'
import moment from "moment";
import TrainOrderRulePopup from '@/page/admin/travel-book/train/component/orderRulePopup/1.0.0/index.vue'

export default {
  data() {
    return {
      orderNo: '',
      changeNo: '',
      orderInfo: {},
      scheduleList: [],
      // showProgress: true,
      showProgress: false,
      //改签单信息
      changeOrderInfo: {},
      //原车次信息
      originalTrainInfo: {},
      //改签车次信息
      changeTrainInfo: {},
      //改签乘客
      changePassengerInfo: [],
      //保险信息
      insuranceInfo: [],
      routeName: '',
      approvalMethod: null,
      refundPassengerShow: false,
      refundPassenger: {},
      reason: '',
      otherReason: '',
      reasonList: ['出差行程变化', '天气因素', '定错车次', '其他'],
      refuncAmount: {},

      orderRuleDialogVisible: false,
    }
  },
  components: {
    TrainOrderRulePopup,
    TrainTimetable
  },
  mounted() {
    this.routeName = this.$route.name;
  },
  async activated() {
    this.orderNo = this.$route.query.orderNo;
    this.changeNo = this.$route.query.changeNo;
    await this.getDetail();
  },
  filters: {
    filterIntervalTime(val) {
      if (val) {
        let m = moment(val).format('mm:ss')
        return m
      } else {
        return '00:00'
      }
    },
    filterApproveStatus(val) {
      // 0.未开始，1.待我审，2.审核通过，3.驳回，4.已失效
      if (val === 0) {
        return '未开始'
      } else if (val === 1) {
        return '待我审'
      } else if (val === 2) {
        return '审核通过'
      } else if (val === 3) {
        return '驳回'
      } else if (val === 4) {
        return '已失效'
      } else {
        return ''
      }
    },
  },
  methods: {
    checkApplyNo() {
      if (this.approvalMethod == 1) {
        // 我的申请
        this.$router.push({
          name: 'admin-my-apply-manage-apply-info',
          query: {
            applyId: this.orderInfo.applyId,
            type: 'info',
            approveType: this.orderInfo.approveType,
            lastMenu: 'adminMyApply'
          }
        });
      } else {
        // 待我审批
        this.$router.push({
          name: 'admin-my-apply-manage-noApprove-detail',
          query: {
            applyId: this.orderInfo.applyId,
            type: 'audit',
            approveType: this.orderInfo.approveType,
          }
        })
      }
    },
    clickInsurance(val) {
      if (val.insuranceOrderId) {
        let data = {
          insuranceOrderId: val.insuranceOrderId,
        };
        consumer_insurance_getEInsuranceOrderUrl(data).then(res => {
          const __this = this;
          const eleLink = document.createElement('a');
          eleLink.href = res.datas.epolicyUrl;
          eleLink.target = '_blank';
          document.body.appendChild(eleLink);
          eleLink.click();
          document.body.removeChild(eleLink);
        })
      } else {
        this.$message.error('正在生成保单信息，请稍后查询...');
      }

    },
    clickSpan() {
      if (this.$route.meta.data_for_back_layout && this.$route.meta.data_for_back_layout.layoutType === 'orderCenterType') {
        this.$router.push({
          name: 'admin-order-center-train-change-list'
        })
      } else {
        this.$router.push({
          name: 'admin-my-order-train-change-list'
        })
      }
    },
    gmtStartFormatter(val) {
      return this.$moment(val.gmtStart).format('YYYY-MM-DD HH:mm')
    },
    gmtArriveFormatter(val) {
      return this.$moment(val.gmtArrive).format('YYYY-MM-DD HH:mm')
    },
    checkEvection() {
      this.$router.push({
        name: 'admin-my-apply-manage-apply-info',
        query: {
          applyId: this.orderInfo.applyId,
          type: 'info',
          approveType: 1,
          lastMenu: 'adminMyApply'
        }
      })
    },
    checkChangeEvection() {
      this.$router.push({
        name: 'admin-my-apply-manage-apply-info',
        query: {
          applyId: this.orderInfo.changeApplyId,
          type: 'info',
          approveType: 4,
          lastMenu: 'adminMyApply'
        }
      })
    },
    async getDetail() {
      // const loading = this.$loading({
      //     lock: true,
      //     text: '',
      //     spinner: 'el-icon-loading',
      //     background: 'rgba(0, 0, 0, 0.7)'
      // });
      let [err, res] = await awaitWrap(consumer_t_od_trains_order_change_getTrainsChangeOrderDetailV2({changeNo: this.changeNo}))
      this.changeOrderInfo = res.datas.changeOrderInfo;
      this.originalTrainInfo = res.datas.originalTrainInfo;
      this.changeTrainInfo = res.datas.changeTrainInfo;
      this.changePassengerInfo = res.datas.changeTrainInfo.changePassengerInfo;
      this.insuranceInfo = res.datas.insuranceInfo;

      if (err) {
        // loading.close();
        return
      }
      // loading.close();
      let orderInfo = res.datas;
      let trainItemInfo = {
        trainNo: orderInfo.originalTrainCode, // 车次
        fromStation: orderInfo.originalChangeFromStationName, // 出发站
        toStation: orderInfo.originalChangeToStationName, // 到达站
        seatName: orderInfo.originalSeatName, // 坐席
        startTime: orderInfo.originalChangeStartDate, //出发时间
        arriveTime: orderInfo.originalChangeToDate, // 到达时间
        ticket: orderInfo.electronicTicketNumber
      };

      //审批进度信息是否展示判断
      // if (!orderInfo.applyId) {
      //     this.showProgress = false
      // }

      if (orderInfo.applyNo) {
        consumer_air_ticket_ApprovalMethod({applyNo: orderInfo.applyNo}).then(res => {
          this.approvalMethod = res.datas.approvalMethod;
        });
      }

      orderInfo.payLimitTime = orderInfo.payLimitTime * 1000;
      if (orderInfo.payLimitTime > 0) {
        this.setPayInterval()
      }

      this.orderInfo = orderInfo;

      this.initTimeTable();
    },
    initTimeTable() {
      let newParams = {
        fromStationCode: this.changeTrainInfo.changeFromStationCode,
        toStationCode: this.changeTrainInfo.changeToStationCode,
        trainCode: this.changeTrainInfo.changeTrainCode,
        trainDate: this.$moment(this.changeTrainInfo.changeStartDate).format("YYYY-MM-DD"),
      };
      this.$refs.aTrainTimetable.init(newParams);

      let oldParams = {
        fromStationCode: this.originalTrainInfo.fromStationCode,
        toStationCode: this.originalTrainInfo.toStationCode,
        trainCode: this.originalTrainInfo.trainCode,
        trainDate: this.$moment(this.originalTrainInfo.startDate).format("YYYY-MM-DD"),
      };
      this.$refs.bTrainTimetable.init(oldParams);
    },
    async getSchedule() {
      //判断改签详情的接口是否有申请单(审批进度)
      if (!this.orderInfo.applyNo) {
        return
      }
      let res = await consumer_trains_order_getSchedule({applyNo: this.orderInfo.applyNo})
      this.scheduleList = res.datas
    },
    startTimeFormatter(val) {
      return this.$moment(val.startTime).format('YYYY-MM-DD HH:mm')
    },
    arriveTimeFormatter(val) {
      return this.$moment(val.arriveTime).format('YYYY-MM-DD HH:mm')
    },
    setStatus(val) {
      // 车票状态 0：待预定，1：待出票，2：出票中，3：出票完成，4：出票失败, 5：退票已申请, 6：退票处理中,
      // 7：退票完成， 8：退票失败，9：改签已申请，10：改签中， 11：改签完成，12：改签失败，
      // 13：改签已取消， 14：已改签， 15：待支付， 16：预定中， 17：占座失败
      let result = '';
      if ([3, 7, 11].indexOf(val) >= 0) {
        result = 'success'//绿色
      } else if ([0, 13].indexOf(val) >= 0) {
        result = 'cancel'//灰色
      }else if ([4, 8, 12, 17].indexOf(val) >= 0) {
        result = 'fail'//红色
      } else {
        result = 'pending'//黄色
      }
      return result
    },
    goRefund(passenger) {
      if (passenger.showRefundButton === 2) {
        return;
      }
      this.refundPassenger = passenger;
      let params = {
        list: [passenger.sn],
        orderNo: this.orderNo
      }
      consumer_admin_trains_order_applyForRefundCost(params).then(res => {
        this.refuncAmount = {
          refundAmount: res.datas.refundAmount,
          refundCharge: res.datas.refundCharge,
          ticketPrice: res.datas.ticketPrice
        }
        this.refundPassengerShow = true;
        this.$nextTick(() => {
          let params = {
            fromStationCode: this.changeTrainInfo.changeFromStationCode,
            toStationCode: this.changeTrainInfo.changeToStationCode,
            trainCode: this.changeTrainInfo.changeTrainCode,
            trainDate: this.$moment(this.changeTrainInfo.changeStartDate).format("YYYY-MM-DD"),
          };
          this.$refs.bTrainTimetable.init(params);
        })
      })
    },
    // 确定退票
    submitRefund() {
      if (this.reason === '') {
        this.$message({
          type: 'warning',
          message: '请勾选退票原因'
        });
        return;
      }

      if (this.reason === '其他' && this.otherReason.trim() === '') {
        this.$message({
          type: 'warning',
          message: '请填写退票原因'
        });
        return;
      }

      let _params = {
        orderNo: this.orderNo,
        passengerSn: this.refundPassenger.sn,
        reason: this.reason === '其他' ? this.otherReason : this.reason,
      };

      const loading = this.$loading({
        lock: true,
        text: '',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });

      consumer_trains_order_applyForRefund(_params).then((res) => {
        loading.close()
        this.refundPassengerShow = false;
        this.$router.push({
          name: 'admin-my-order-train-refund-detail',
          query: {
            refundNo: res.refundNo,
          }
        })
      }).catch(err => {
        this.refundPassengerShow = false;
        loading.close()
      });
    },
    handleCloseRefund() {
      this.refundPassengerShow = false;
    },
    //去支付
    toPay() {
      this.$router.push({
        name: 'admin-train-change-submit-pay',
        query: {
          orderNo: this.orderNo,
          changeNo: this.changeNo,
        }
      })
    },
    //取消订单
    toCancelOrder() {
      this.$confirm('此操作将取消该订单的改签, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const loading = this.$loading({
          lock: true,
          text: '',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        consumer_trains_order_cancelChangeOrder({changeNo: this.changeNo}).then(res => {
          loading.close()
          let ifIsSuccessful = res.datas.ifIsSuccessful
          if (ifIsSuccessful === 1) {
            this.$message({
              type: 'success',
              message: '取消成功'
            })
            this.getDetail();
          } else {
            this.$message({
              type: 'warning',
              message: '取消改签失败，请稍后再试'
            })
          }
        }).catch(() => {
          loading.close()
        })
      }).catch(() => {

      })
    },
    //剩余时间倒计时
    setPayInterval() {
      this.intervalFun = setInterval(() => {
        this.orderInfo.payLimitTime = this.orderInfo.payLimitTime - 1000
        if (this.orderInfo.payLimitTime <= 0) {
          clearInterval(this.intervalFun)
          this.getDetail();
        }
      }, 1000)
    },
    back() {
      let jumpType = this.$route.query.jumpType ? this.$route.query.jumpType : ''
      if (jumpType == 'list') {
        this.$router.push({
          name: 'admin-my-order-train-change-list',
        });
      } else {
        this.$router.back();
      }
    },
    showRulePopup() {
      this.orderRuleDialogVisible = true;
    },
  }
}
